<template>
  <ContainerPage>
    <div class="p-3 p-lg-5 rounded w-100 shadow-sm mb-3" v-background-3 v-if="!complete">
      <div class="d-flex flex-column flex-md-row align-items-center mb-3">
        <img src="/images/meeting.svg" alt="arkadia" class="w-25 p-md-3 mr-md-3" />
        <div class="w-100">
          <h1 class="mb-0">Bienvenue</h1>
          <p>
            En préparation de votre entretien, merci de compléter les informations demandées dans le questionnaire
            ci-après.
          </p>
        </div>
      </div>
      <div>
        <div>
          <div class="mb-1"><strong>1. Comment avez vous connu Arkadia</strong></div>
          <div class="mb-3">
            <Checkbox v-model="q1" text="Réseaux sociaux (Linkedin, Facebook...)"></Checkbox>
            <Checkbox v-model="q2" text="Joboard (Apec, Indeed, WebIngenierie...)"></Checkbox>
            <Checkbox v-model="q3" text="Site web Arkadia group"></Checkbox>
            <Checkbox v-model="q4" text="Bouche à Oreille"></Checkbox>
            <Checkbox v-model="q5" text="Arkadia vous a trouvé"></Checkbox>
          </div>
        </div>
        <div>
          <div class="mb-1"><strong>2. Votre expérience dans une fonction similaire (années)</strong></div>
          <div class="mb-3">
            <Checkbox v-model="q6" text="Moins d'un an"></Checkbox>
            <Checkbox v-model="q7" text="Entre 1 et 3 ans"></Checkbox>
            <Checkbox v-model="q8" text="Entre 3 et 6 ans"></Checkbox>
            <Checkbox v-model="q9" text="Plus de 6 ans"></Checkbox>
          </div>
        </div>
        <div>
          <div class="mb-1"><strong>3. Êtes-vous déjà intervenu en zone contrôlée (nucléaire) ?</strong></div>
          <div class="d-flex mb-3">
            <base-button
              class="mr-3"
              :class="{ 'btn-primary': q10 == 1, 'btn-secondary': q10 != 1 }"
              text="Oui"
              @click="q10 = 1"
              icon="check"
            ></base-button>
            <base-button
              :class="{ 'btn-primary': q10 == 2, 'btn-secondary': q10 !== 2 }"
              text="Non"
              @click="q10 = 2"
              icon="check"
            ></base-button>
          </div>
        </div>
        <div>
          <div class="mb-1"><strong>4. Êtes-vous déjà intervenu en zone ATEX (pétrochimie) ?</strong></div>
          <div class="d-flex mb-3">
            <base-button
              class="mr-3"
              :class="{ 'btn-primary': q11 == 1, 'btn-secondary': q11 != 1 }"
              text="Oui"
              @click="q11 = 1"
              icon="check"
            ></base-button>
            <base-button
              :class="{ 'btn-primary': q11 == 2, 'btn-secondary': q11 !== 2 }"
              text="Non"
              @click="q11 = 2"
              icon="check"
            ></base-button>
          </div>
        </div>
        <div>
          <div class="mb-1"><strong>5. Qu’est ce qu’un document unique ?</strong></div>
          <div class="mb-3">
            <Checkbox v-model="q12" text="Un document qui présente l’évaluation des risques de l’entreprise"></Checkbox>
            <Checkbox v-model="q13" text="Un document disponible en 1 seul exemplaire"></Checkbox>
          </div>
        </div>
        <div>
          <div class="mb-1"><strong>6. Comment savoir si les produits que j’utilise sont dangereux ?</strong></div>
          <div class="mb-3">
            <Checkbox v-model="q14" text="Notice de sécurité"></Checkbox>
            <Checkbox v-model="q15" text="La FDS (fiche de données sécurité)"></Checkbox>
            <Checkbox v-model="q16" text="Étiquette"></Checkbox>
            <Checkbox v-model="q17" text="Internet"></Checkbox>
          </div>
        </div>
        <div>
          <div class="mb-1"><strong>7. Votre moyen de déplacement habituel ?</strong></div>
          <div class="mb-3">
            <Checkbox v-model="q18" text="Auto"></Checkbox>
            <Checkbox v-model="q19" text="Transports en commun"></Checkbox>
            <Checkbox v-model="q20" text="à pieds"></Checkbox>
            <Checkbox v-model="q21" text="à vélo"></Checkbox>
          </div>
        </div>
        <div>
          <div class="mb-1"><strong>8. Cochez les EPI obligatoires pour une intervention (au minimum)</strong></div>
          <div class="mb-3">
            <Checkbox v-model="q22" text="Chaussures de sécurité"></Checkbox>
            <Checkbox v-model="q23" text="Tenue de travail (tenue Arkadia)"></Checkbox>
            <Checkbox v-model="q24" text="Lunette de sécurité"></Checkbox>
            <Checkbox v-model="q25" text="Masque à cartouche"></Checkbox>
          </div>
        </div>
        <div>
          <div class="mb-1"><strong>9. Vous vous trouvez face à un danger, que faites-vous ?</strong></div>
          <div class="mb-3">
            <Checkbox v-model="q26" text="Je suis là pour travailler, je travaille"></Checkbox>
            <Checkbox
              v-model="q27"
              text="Je mets mon chantier en sécurité, j’évacue et je préviens mon responsable"
            ></Checkbox>
            <Checkbox v-model="q28" text="Je refuse de travailler, je rentre chez moi"></Checkbox>
            <Checkbox v-model="q29" text="Je balise la zone si cela est possible"></Checkbox>
          </div>
        </div>
        <div>
          <div class="mb-1"><strong>10. Qu'est ce qu'un plan de prévention ?</strong></div>
          <div class="mb-3">
            <Checkbox v-model="q30" text="Un plan des locaux"></Checkbox>
            <Checkbox
              v-model="q31"
              text="Un document qui présente les risques et les parades liés à la co-activité"
            ></Checkbox>
            <Checkbox v-model="q32" text="Un plan pour la prévention des risques"></Checkbox>
            <Checkbox v-model="q33" text="Une analyse des risques"></Checkbox>
          </div>
        </div>
        <div class="d-flex justify-content-end">
          <base-button class="" text="Envoyer mes réponses" :loading="loading" @click="send" icon="check"></base-button>
        </div>
      </div>
    </div>
    <div v-else class="p-3 p-lg-5 rounded w-100 shadow-sm mb-3" v-background-3>
      Merci, vous avez complété le formulaire
    </div>
  </ContainerPage>
</template>
<script>
import ContainerPage from "@/components/containers/ContainerPage.vue";
import Checkbox from "@/components/bases/Checkbox.vue";
import BaseButton from "@/components/bases/Button.vue";
import { errHandler } from "@/helpers.js";

export default {
  name: "Admin",
  components: {
    ContainerPage,
    Checkbox,
    BaseButton,
  },
  data() {
    return {
      url: "",
      q1: null,
      q2: null,
      q3: null,
      q4: null,
      q5: null,
      q6: null,
      q7: null,
      q8: null,
      q9: null,
      q10: null,
      q11: null,
      q12: null,
      q13: null,
      q14: null,
      q15: null,
      q16: null,
      q17: null,
      q18: null,
      q19: null,
      q20: null,
      q21: null,
      q22: null,
      q23: null,
      q24: null,
      q25: null,
      q26: null,
      q27: null,
      q28: null,
      q29: null,
      q30: null,
      q31: null,
      q32: null,
      q33: null,
      complete: false,
      feedback: {},
      loading: false,
    };
  },
  mounted() {
    this.url = this.$route.params.url;
  },
  methods: {
    send() {
      let question1 = 0;
      let question2 = 0;
      let question3 = 0;
      let question4 = 0;
      let question5 = 0;
      let question6 = 0;
      let question7 = 0;
      let question8 = 0;
      let question9 = 0;
      let question10 = 0;

      if (this.q1 == 1) question1 = 1;
      if (this.q2 == 1) question1 = 2;
      if (this.q3 == 1) question1 = 3;
      if (this.q4 == 1) question1 = 4;
      if (this.q5 == 1) question1 = 5;

      if (this.q6 == 1) question2 = 1;
      if (this.q7 == 1) question2 = 2;
      if (this.q8 == 1) question2 = 3;
      if (this.q9 == 1) question2 = 4;

      if (this.q10 == 1) question3 = 1;
      if (this.q10 == 2) question3 = 2;

      if (this.q11 == 1) question4 = 1;
      if (this.q11 == 2) question4 = 2;

      if (this.q12 == 1) question5 = 1;
      if (this.q13 == 1) question5 = 0;

      if (this.q14 == 1 && this.q15 == 1 && this.q16 == 1) question6 = 1;
      if (this.q17 == 1) question6 = 0;

      if (this.q18 == 1) question7 = 1;
      if (this.q19 == 1) question7 = 2;
      if (this.q20 == 1) question7 = 3;
      if (this.q21 == 1) question7 = 4;

      if (this.q22 == 1 && this.q23 == 1 && this.q24 == 1) question8 = 1;
      if (this.q25 == 1) question8 = 0;

      if (this.q26 == 1) question9 = 0;
      if (this.q27 == 1 && this.q29 == 1) question9 = 1;
      if (this.q28 == 1) question9 = 0;

      if (this.q30 == 1) question10 = 0;
      if (this.q31 == 1) question10 = 1;
      if (this.q32 == 1) question10 = 0;
      if (this.q33 == 1) question10 = 0;

      this.loading = true;
      this.$http
        .post("/qualification", {
          url: this.url,
          q1: question1,
          q2: question2,
          q3: question3,
          q4: question4,
          q5: question5,
          q6: question6,
          q7: question7,
          q8: question8,
          q9: question9,
          q10: question10,
        })
        .then(() => {
          this.complete = true;
        })
        .catch((error) => (this.feedback = errHandler(error)))
        .finally(() => (this.loading = false));
    },
  },
};
</script>
<style scoped></style>
